import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import SEO from "../seo"

import { css } from "@emotion/core"
import {
  ContentWrapper,
  ContentHeader,
  ContentTitle,
  ContentBody,
  ContentText,
} from "../parts/content"

const Page = ({ data }) => {
  const node = data.markdownRemark
  return (
    <Layout>
      <SEO title={node.frontmatter.title} description={node.excerpt}></SEO>
      <ContentWrapper
        key="{props.id}"
        css={css`
          border: solid 2px;
          border-color: #393e46;
          margin-bottom: 15px;
        `}
      >
        <ContentHeader>
          <ContentTitle tag="h1">{node.frontmatter.title}</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <ContentText
            dangerouslySetInnerHTML={{ __html: node.html }}
          ></ContentText>
        </ContentBody>
      </ContentWrapper>
    </Layout>
  )
}
export default Page;

export const query = graphql`
  query($slug: String!) {
    adminYaml {
      collections {
        name
        fields {
          name
          label
          widget
          required
          options {
            label
            value
          }
        }
      }
    }
    markdownRemark(fields: { pagetype: { eq: "pages" }, slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "YYYY.MM.DD")
      }
      html
      excerpt
      fields {
        slug
      }
    }
  }
`
